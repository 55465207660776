const url = {
	el: {
		doc: $(document),
		linkToNewTab: $('a[target="_blank"]')
	},

	events(url) {
		const newTab = window.open()
		newTab.opener = null
		newTab.location = url
	},

	init() {
		(this.el.linkToNewTab.length ? this.el.linkToNewTab.attr('rel', 'noopener noreferrer') : '')

		this.el.doc.on('click', 'a[target="_blank"]', e => {
			this.events(e.currentTarget)
			return false
		})
	}
}

url.init()