/* global Swiper */
const sliders = {
	el: {
		win: $(window),
		slider: $('.js-slider')
	},

	events(el) {
		const sliderCentered = $(el).data('slider-centered'),
			sliderDelay = $(el).data('slider-delay'),
			sliderLoop = $(el).data('slider-loop'),
			sliderAutoPlay = $(el).data('slider-autoplay'),
			sliderSizeWide = $(el).data('slider-wide'),
			sliderSizeLarge = $(el).data('slider-large'),
			sliderSizeMedium = $(el).data('slider-medium'),
			sliderSizeSmall = $(el).data('slider-small'),
			sliderPaginationType = $(el).data('slider-pagination-type'),
			sliderSpaceBetween = $(el).data('slider-space-between'),
			sliderOnInteraction = $(el).data('slider-on-interaction'),
			sliderNoSwipe = $(el).data('slider-no-swipe'),
			sliderFreeMode = $(el).data('slider-freemode'),
			sliderFreeModeSticky = $(el).data('slider-freemode-sticky'),
			sliderKeyboard = $(el).data('slider-keyboard'),
			sliderMouseWheel = $(el).data('slider-mousewheel')

		new Swiper(el, {
			slidesPerView: sliderSizeSmall ? sliderSizeSmall : 1,
			centeredSlides: sliderCentered ? sliderCentered : false,
			loop: sliderLoop ? sliderLoop : false,
			spaceBetween: sliderSpaceBetween ? sliderSpaceBetween : 0,
			noSwiping: sliderNoSwipe ? sliderNoSwipe : false,
			freeMode: sliderFreeMode ? sliderFreeMode : false,
			freeModeSticky: sliderFreeMode && sliderFreeModeSticky ? sliderFreeModeSticky : false,
			slideToClickedSlide: sliderFreeMode ? true : false,
			mousewheel: sliderFreeMode ? {
				releaseOnEdges: true
			} : sliderMouseWheel ? sliderMouseWheel : false,

			keyboard: {
				enabled: sliderKeyboard ? sliderKeyboard : false
			},

			navigation: {
				nextEl: $(el).find('.swiper-button-next'),
				prevEl: $(el).find('.swiper-button-prev')
			},

			pagination: {
				el: $(el).find('.swiper-pagination'),
				clickable: sliderPaginationType ? false : true,
				type: sliderPaginationType ? sliderPaginationType : 'bullets', //"bullets", "fraction", "progressbar" or "custom"  (default:"bullets")
				dynamicBullets: true,
				renderBullet: (index, className) => {
					const currentSlider = $(el).find('.swiper-slide').eq(index),
						currentText = currentSlider.data('text'),
						currentImg = currentSlider.data('src')

					return `
						<div class="slider__pagination ${className}">
							<div class="slider__pagination-img-wrapper">
								<img class="slider__pagination-img img-full" src="${currentImg}">
							</div>
							<div class="slider__pagination-text">${currentText}</div>
						</div>
						`
				}
			},

			autoplay: sliderAutoPlay === false ? sliderAutoPlay : sliderAutoPlay === true ? {
				delay: sliderDelay ? sliderDelay : 5000,
				disableOnInteraction: sliderOnInteraction ? sliderOnInteraction : false
			} : '',

			observer: true,

			breakpoints: {
				768: { //tablet up
					slidesPerView: sliderSizeMedium ? sliderSizeMedium : sliderSizeSmall ? sliderSizeSmall : 1
				},
				1024: { //small desktop up
					slidesPerView: sliderSizeLarge ? sliderSizeLarge : sliderSizeMedium ? sliderSizeMedium : 1
				},
				1200: { // desktop up
					slidesPerView: sliderSizeWide ? sliderSizeWide : sliderSizeLarge ? sliderSizeLarge : 1,
					pagination: {
						dynamicBullets: false
					}
				}
			},
			on: {
				init: function () {
					if(!$('html').hasClass('k-ie11')) {
						setTimeout(() => {
							window.dispatchEvent(new Event('resize'))
						}, 200)
						if((window.matchMedia('(min-width: 1200px)').matches) && this.$el.hasClass('is-customization-swiper')) {
							this.$wrapperEl.css('display', 'none')
						}				
					} else {
						if((window.matchMedia('(min-width: 1200px)').matches) && this.$el.hasClass('is-customization-swiper')) {
							this.$wrapperEl.css('height', '1px')
							this.$wrapperEl.css('opacity', '0')
						}
					}						
				},
				click: function () {
					const imgContainer = $(el).closest('.theme-collection').find('.theme-collection-row-desktop img')
					imgContainer.fadeOut(200)
					setTimeout (() => {
						imgContainer.attr('alt', $(el).find('.swiper-slide-active').data('text'))
						imgContainer.attr('src', $(el).find('.swiper-slide-active').data('src')).fadeIn(200)
					}, 200)
				},
				resize: function () {
					if(!$('html').hasClass('k-ie11')) {
						if((window.matchMedia('(min-width: 1200px)').matches) && this.$el.hasClass('is-customization-swiper')) {
							this.$wrapperEl.css('display', 'none')
						}
						if((window.matchMedia('(max-width: 1200px)').matches) && this.$el.hasClass('is-customization-swiper')) {
							this.$wrapperEl.css('display', 'flex')
						}
					}
				}
			}
		})
	},

	init() {
		this.el.slider.each((i, el) => {
			const sliderItems = $(el).find('.swiper-slide')
			sliderItems.length > 1 ? this.events(el) : ''
		})
	}
}
sliders.init()