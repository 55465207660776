const accessibility = {
	el: {
		doc: $(document),
		html: $('html')
	},

	events() {
		this.el.doc
			.on('keydown', e => e.key === 'Tab' ? this.el.html.addClass('accessibility') : '')
			.on('click', () => this.el.html.removeClass('accessibility'))
	},

	init() {
		this.events()
	}

}

accessibility.init()