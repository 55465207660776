const menu = {
	el: {
		list: $('.fvr-menu-horizontal')
	},

	events () {
		this.el.list.find('.has-sublist').each((i,el) => {
			if($(el).find('.with-subcategories').next().hasClass('dropdown')) {
				$(el).on('click', e => {
					console.log('true')
					this.el.list.find('li').removeClass('active')
					$(e.currentTarget).find('.dropdown').hasClass('active') ? $(e.currentTarget).removeClass('active') : $(e.currentTarget).addClass('active')
				})
			}
		})
	},

	init () {
		this.events()
	}
}

menu.init()