const modal = {
	el: {
		btn: $('.js-modal-btn'),
		modalContainer: $('.js-modal'),
		body: $('body')
	},

	events () {
		this.el.btn.on('click', () => {
			this.el.modalContainer.addClass('open')
			this.el.body.addClass('no-scroll show-scrollbar')
		})

		this.el.modalContainer.find('.js-modal-close').on('click', (e) => {
			$(e.currentTarget).closest('.js-modal').removeClass('open')
			this.el.body.removeClass('no-scroll show-scrollbar')
		})
	},

	init () {
		this.events()
	}

}

modal.init()