//FIX must be 100% Vanilla JS (ES5) to check older IE versions <9
const detectIE = {

	el: {
		body: document.querySelector('body')
	},

	vars: {
		msie: window.navigator.userAgent.indexOf('MSIE') //IE6-10
		//trident: window.navigator.userAgent.indexOf('Trident/') IE11
	},

	events() {
		const oldBrowserBody = this.el.body
		//FIX IE8 classname += '' instead add() for IE9 lower
		this.vars.msie > 0 ?
			(oldBrowserBody.className += 'old-browser', oldBrowserBody.insertAdjacentHTML('afterbegin', '<span class="old-browser__message">Navegador não suportado. Clique&nbsp;<a target="_blank" href="http://outdatedbrowser.com/pt">aqui</a>&nbsp;para actualizar</span>')) : ''
	},
	init() {
		this.events()
	}

}

detectIE.init()