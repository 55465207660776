const dropdown = {
	el: {
		btn: $('.js-dropdown')
	},

	events() {
		this.el.btn.on('click', e => {
			$(e.currentTarget).closest('.theme-dropdown-item').toggleClass('open')
			$(e.currentTarget).next().slideToggle()
		})
	},

	init() {
		this.events()
	}
}

dropdown.init()